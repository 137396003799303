// import { Link, useNavigate } from 'react-router-dom'
// import '../../assets/stylesheets/Content/footer.scss'
// import DOMPurify from 'dompurify'
// import { getButtonInfo, getTextValue } from '../Admin/CMS/cms_helper'

// export default function Footer({pageInfo}){

//     const navigate = useNavigate()
//     const template_name = 'footer'

//     return <>
//         <div className="content_footer_wrapper">
//             <div className="content_footer_container">
//                 <div className="footer_bottom">
//                     <div className="footer_section">
//                         <div className="footer_content">
//                             <ul className="footer_ul">
//                             <li><Link to={"/about?lang=en"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTextValue(template_name,'block_1',pageInfo),{ADD_ATTR: ['target'] })}}></Link></li>
//                             <li> <Link to={"/vision?lang=en"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTextValue(template_name,'block_2',pageInfo),{ADD_ATTR: ['target'] })}}></Link></li>
//                             <li> <Link to={"/our-history?lang=en"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTextValue(template_name,'block_3',pageInfo),{ADD_ATTR: ['target'] })}}></Link></li>
//                             <button className='help_button' onClick={()=>{
//                                         const button_info = getButtonInfo(template_name,'block_4',pageInfo) 
//                                         const button_link = button_info?.link
//                                         const button_tab = button_info?.link_tab
//                                         if(button_tab === 'new_tab'){
//                                             window.open(button_link,'_blank','noopener')
//                                         }
//                                         else{
//                                         window.open(button_link,'_self')
//                                         }
                                        
//                                     }}>{getButtonInfo(template_name,'block_4',pageInfo)?.name}</button>
//                             </ul>
//                         </div>
//                     </div>
//                     <div className="footer_section">
//                         <div className="footer_content">
//                             <ul className="footer_ul">
//                             <li><a target="_top" href="/support?lang=en">Support</a></li>
//                             <li><a target="_blank" href="https://alaiamind.squarespace.com/">Blog</a></li>
//                             <li><a target="_top" href="/terms?lang=en">Terms</a></li>
//                             <li><a target="_top" href="/privacy?lang=en">Privacy</a></li>
//                             </ul>
//                         </div>
//                     </div>
            
//                     <div className="footer_section">
//                         <div className="footer_content">
//                             <p className="platform_link"><a target="_top" href="/platform?lang=en">Usability Across Platforms</a></p>
//                             <ul className="footer_downloads_ul">
//                             <li>
//                                 <a className="store_icon first" target="_blank" rel="nofollow" aria-label="Download Alaia from App Store" title="Download Alaia from App Store" href="https://apps.apple.com/us/app/id1515659483"></a>
//                             </li>
//                             <li>
//                                 <a className="store_icon second" target="_blank" rel="nofollow" aria-label="Download Alaia from Google Play" title="Download Alaia from Google Play" href="https://play.google.com/store/apps/details?id=com.alaiamind.alaia"></a>
//                             </li>
//                             <li>
//                                 <a className="store_icon third" target="_blank" rel="nofollow" aria-label="Download Alaia from Chrome Web Store" title="Download Alaia from Chrome Web Store" href="https://chrome.google.com/webstore/detail/alaia/lomhgkajlmbdljaofgkonhnblcghmdjm"></a>
//                             </li>
//                             <li>
//                                 <a className="store_icon fourth" target="_blank" aria-label="Download Alaia for Windows" title="Download Alaia for Windows" href="https://support.alaiamind.com/article/134-download-links"></a>
//                             </li>
//                             <li>
//                                 <a className="store_icon fifth" target="_blank" aria-label="Download Alaia for macOS" title="Download Alaia for macOS" href="https://support.alaiamind.com/article/134-download-links"></a>
//                             </li>
//                             </ul>
//                         </div>
//                     </div>
//                     <div className="footer_section">
//                         <div className="footer_content">
//                             <ul className="social_icon_container">
//                                 <li>
//                                     <a className="icon_social facebook" target="_blank" rel="nofollow" aria-label="Facebook" title="Facebook" href="https://www.facebook.com/alaiamind"></a>
//                                 </li>
//                                 <li>
//                                     <a className="icon_social insta" target="_blank" rel="nofollow" aria-label="Instagram" title="Instagram" href="https://www.instagram.com/alaiamind"></a>
//                                 </li>
//                                 <li>
//                                     <a className="icon_social twitter" target="_blank" rel="nofollow" aria-label="Twitter" title="Twitter" href="https://www.twitter.com/alaiamind"></a>
//                                 </li>
//                                 </ul>

//                             <p className="f_join_label">Join the Alaiance</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </>
// }

import React from "react";
import AlaiaLogo from '../../assets/images/Alaia.svg'
import PlaystoreImage from '../../assets/images/playstore.png'
import AppstoreImage from '../../assets/images/appstore.png'
import '../../assets/stylesheets/Content/footer.scss'
import SubFooter from "./_SubFooter";
import { useNavigate } from "react-router-dom";
export default function Footer(){

    const navigate = useNavigate();
    let current_url = window.location.href.split('?')[0]
    let current_page = current_url.split('/').filter(Boolean).pop();
    if(window.location.hostname === current_page){
        current_page = 'home'
    }   

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
    };

    return <>
        <div className={`content_footer_wrapper ${current_page}`}>
            <div className="content_footer_container">
            <footer className="content_footer">
        
                <div className="content_footer__logo"  onClick={()=>{navigate('/')}}> 
                    <img src={AlaiaLogo} alt="Logo" loading="lazy"/>
               </div>
               <div className="responsive_content_footer__logo_container"> 
                    <img src={AlaiaLogo} alt="Logo" loading="lazy" onClick={()=>{navigate('/')}}/>
                    <div className="responsive_content_footer__column content_images">
                   
                        <li className="logo_list"><img src={AppstoreImage} alt="Logo" loading="lazy"/></li>
                        <li className="logo_list"><img src={PlaystoreImage} alt="Logo" loading="lazy"/></li>
              
                    </div>
               </div>
                <div className="content_footer__columns">
                    {/* <div className="content_footer__column">
                        <ul>
                        <li className="content_footer_first_column">FAQ</li>
                        <li className="content_footer_first_column">Contact us</li>
                        <li className="content_footer_first_column">Help</li>
                        <li className="content_footer_first_column">Blog</li>
                        </ul>
                    </div> */}
                    <div className="content_footer__column">
                        <ul>
                        <li className="content_footer_bold_menu">Info</li>
                        <li onClick={()=>{navigate('/')}}>Home</li>
                        <li onClick={()=>{navigate('/wellness')}}>Wellness</li>
                        <li onClick={()=>{navigate('/learn')}}>Learn</li>
                        <li onClick={()=>{navigate('/impact')}}>Impact</li>
                        </ul>
                    </div>
                    <div className="content_footer__column">
                        <ul>
                        <li className="content_footer_bold_menu">About</li>
                        <li  onClick={()=>{navigate('/about-us')}}>About Us</li>
                        <li onClick={()=>{navigate('/impact')}}>Research</li>
                        <li>Blog</li>
                        </ul>
                    </div>
                    <div className="content_footer__column">
                        <ul>
                        <li className="content_footer_bold_menu">Get Started</li>
                        <li><a href='/sign-up'>Try Alaia</a></li>
                        <li> <a href='/login'>Sign In</a></li>
                        {/* <li>Contact</li> */}
                        </ul>
                    </div>
                    {/* <div className="content_footer__column fifth_column">
                        <ul>
                        <li className="content_footer_bold_menu">Learn</li>
                        </ul>
                    </div> */}
                    <div className="content_footer__column content_images">
                   
                        <li><img src={AppstoreImage} alt="Logo" loading="lazy"/></li>
                        <li><img src={PlaystoreImage} alt="Logo" loading="lazy"/></li>
                   
                    </div>
                </div>
               
                <div className="arrow_container"><div className="arrow" onClick={()=>{scrollToTop()}}></div></div>
        </footer>
    </div>
  </div>
  <SubFooter/>
    </>
  
}