import { useEffect, useState, useRef } from 'react'
import { getUserData } from './helpers';
import { UsageLogApi } from './Events';
import { formatDateToDateTime } from './helpers';
export default function UsageTracking(){

    const UpdateTimer = useRef();
    const userInfo = getUserData()?.user_info;
    const initialize = JSON.parse(localStorage.getItem("usage_tracking") || "{}");
    if(Object.keys(initialize).length == 0){
        localStorage.setItem('usage_tracking',JSON.stringify({
            url: window.location.href,
            usage_type: find_type(window.location.href),
            start_time: Date.now(),
        }));
    }
    const [firstload, setFirstload] = useState(true);
    const [usageType, setUsageType] = useState(find_type(window.location.href))
    const homePage = JSON.parse(localStorage.getItem("page_hash") || "{}");
    const lastPageType = localStorage.getItem('usage_type')

    function find_type(url){
        if(url){
            if(url.indexOf('view') > -1) 
                return '';
            if(url.indexOf('reflect') > -1) 
                return 'reflect';
            else if(url.indexOf('activity') > -1)
                return 'activity';
            else if(url.indexOf('learn') > -1){
                if(url.indexOf('admin') > -1) return 'admin-learn';
                else return 'learn';
            }
            else if(url.indexOf('home') > -1) return 'mindfulness'
            else return ''
        }
        else return ''
    }

    //update the usage for every 5 mins or if user navigates to another page
     async function updateUsage(homePage={}){
        const page = JSON.parse(localStorage.getItem("usage_tracking") || "{}");
        if(page.usage_type != '' && Object.keys(userInfo || {}).length > 0){
            const user_start_time = formatDateToDateTime(new Date(page.start_time));
            const user_end_time = formatDateToDateTime(new Date());
            const start_duration = parseInt(parseInt(page.start_time)/1000);
            const end_duration = parseInt(parseInt(Date.now())/1000);
            let payload = {
                Client: {platform: 'web'},
                Usage: {
                    user_account_uuid: userInfo.user_account_uuid,
                    usage_type: page.usage_type,
                    start_time: start_duration,
                    end_time: end_duration,
                    user_start_time: user_start_time,
                    user_end_time: user_end_time,
                    log_date: user_start_time.substring(0,10),
                    used_seconds: end_duration - start_duration
                }
            }
        
            const u_type = find_type(window.location.href)
            localStorage.setItem('usage_tracking',JSON.stringify({
                url: window.location.href,
                usage_type: u_type,
                start_time: Date.now(),
            }));
            localStorage.setItem('usage_type',u_type) 

            if(Object.keys(homePage).length > 0){
                const user_start_time_pg = formatDateToDateTime(new Date(homePage.start_time));
                const start_time_pg = parseInt(parseInt(homePage.start_time)/1000);
                if(window.location.href != homePage.url && start_time_pg < start_duration){
                
                    payload.Usage.usage_type = find_type(homePage.url);
                    payload.Usage.start_time = start_time_pg;
                    payload.Usage.user_start_time = user_start_time_pg;
                    payload.Usage.log_date = user_start_time_pg.substring(0,10);
                    payload.Usage.used_seconds = end_duration - start_time_pg;
                }
                localStorage.removeItem('page_hash');
                
            }

            if(payload.Usage.used_seconds < 10 || payload.Usage.used_seconds > 200){
                clearInterval(UpdateTimer.current);
                UpdateTimer.current = setInterval(()=> {updateUsage()},180000); 
                return;
            }

            if(payload.Usage.usage_type != 'admin-learn'){
                const response = await UsageLogApi({ mwsRequest: {String: {Request: payload}}});
                if(response?.data?.mwsResponse?.Status?.code === '000'){
                    localStorage.removeItem('page_hash')
                }
            }
            
        }
        else{
             clearInterval(UpdateTimer.current);
        }

    }

    useEffect(()=>{
        window.addEventListener('unload', (event)=>{
            event.preventDefault();
            const home_page_type = find_type(homePage?.url)
            if(usageType == lastPageType){
                updateUsage();
            }
        })
        return () => {
        window.removeEventListener('unload', updateUsage);
        };
    },[])

    // useEffect(()=>{
    //     window.addEventListener('blur', (event)=>{
    //         event.preventDefault();
    //         console.log('blur')
    //             clearInterval(UpdateTimer)
    //             updateUsage();
            
    //     })
    //     return () => {
    //     window.removeEventListener('blur', updateUsage);
    //     };
    // },[])

    // useEffect(()=>{
    //     window.addEventListener('focus', (event)=>{
    //         event.preventDefault();
    //         console.log('focus')
    //             UpdateTimer.current = setInterval(()=> {updateUsage()},30000); 
    //             updateUsage();
            
    //     })
    //     return () => {
    //     window.removeEventListener('focus', updateUsage);
    //     };
    // },[])

    useEffect(()=>{
        UpdateTimer.current = setInterval(()=> {updateUsage()},180000); //timer to update every 5 minutes
    },[])
    
    useEffect(()=>{
        
        const current_page_type = find_type(window.location.href)
        const home_page_type = find_type(homePage?.url)
        
        if((!firstload && usageType!= current_page_type) || usageType != lastPageType){
            updateUsage(homePage);

            //reset the timer if the page is changed
            setUsageType(current_page_type)
            clearInterval(UpdateTimer.current);
            UpdateTimer.current = setInterval(()=> {updateUsage()},180000); 
        }
        else
            setFirstload(false);

    },[window.location.href])

}