import {React, useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import NavHeader from './NavHeader';
import { getUserData, clearUserData } from '../../services/helpers';
import '../../assets/stylesheets/shared/nav_menu.scss'
import { useRef } from 'react';
import { logoutUser } from '../../services/Events';

const NavMenu = () => {
  const userInfo = getUserData();
  const logoutRef = useRef();
  const navRef = useRef();
  const [islearn, setIslearn] = useState(false)
  const [showLogoutPopup , setShowLogoutPopup] = useState(false)
  const is_demo = userInfo?.user_info.user_account_uuid === '0853f8df-2f93-4762-b907-0da9001d56b7' || userInfo?.user_info.user_account_uuid === '2cec55bb-1a87-4405-9159-25f1e6073020';
  const permissions = []
  
  if(Object.keys(userInfo?.user_info || {}).length > 0){
    const colegia_permissions = userInfo.user_info.permissions || []
    if(colegia_permissions.length > 0){
      colegia_permissions.forEach(function(obj){
          permissions.push(...obj.permissions)
      })
    }
  }

  let menuList = [{ path: '/home', name: 'Home', id: 'home' },
    { path: '/activity', name: 'Activity', id: 'activity' },
    // { path: '/discover', name: 'Discover', id: 'discover' },
    { path: '/reflect', name: 'Reflect', id: 'reflect' }]

  if( permissions.includes('learn') || permissions.includes('demo_learn'))
    menuList.push({path: '/teacher/learn', name: 'Learn', id: 'learn'})

//   if(is_demo && permissions.includes('demo_learn'))
//     menuList = [{path: '/teacher/learn', name: 'Learn', id: 'learn'}]


  function redirect_url(url){
    window.location.href = url
    clearUserData();
  }

   const outsideFilterClick = (event) => {
        if (logoutRef.current) {
        if (logoutRef.current.contains(event.target)) {
            // inside click
            return;
        }
        // outside click
         setShowLogoutPopup(false)
        }
    }

     async function userlogout(){
        let payload = 
          { mwsRequest:{ String: 
            {Request:
            { Session: { token: '' ,loggedin_user_type: userInfo.user_info.user_type},
              Client: {platform: 'web',identity: '', version:"1.0", session_hash:""}
          }}}}

        try {         
            const response = await logoutUser(payload)

            if(response){
            if (response.data.mwsResponse.Status.code === '000'){
                console.log(response.data)
            }
            else{
            let error = response.data.mwsResponse.Status.message
            console.log(error)
            }
        }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        document.addEventListener('click', outsideFilterClick);
        return () => {
            document.removeEventListener('click', outsideFilterClick);
        };
    }, [])

  
    function expandpage(e){
        let page_container = document.getElementById('app_main_page_container')
        if(window.innerWidth > 1240 && window.location.href.indexOf('teacher') > -1){
            page_container.style.paddingLeft = '166px'  
            let teacher_header = document.getElementById('teacher_lesson_content_header')
            if(teacher_header){
            teacher_header.style.width = 'calc(100% - 230px)'
            teacher_header.style.transition = ' all 0.4s ease-out'
            }
            let teacher_bread_crumb = document.getElementById('teacher_breadcrumb_id')
            if(teacher_bread_crumb){
            teacher_bread_crumb.style.marginLeft = '0px'
            teacher_bread_crumb.style.transition = ' all 0.4s ease-out'
            let logout_id = document.getElementById('logout_id')
            if(logout_id){
                logout_id.style.marginLeft = '-85px'
                logout_id.style.transition = ' all 0.4s ease-out'
            }
            }
        } 

    }

   function compresspage(e){
        let page_container = document.getElementById('app_main_page_container')
        if(window.innerWidth > 1240 && window.location.href.indexOf('teacher') > -1){
            page_container.style.paddingLeft = '80px'
            let teacher_header = document.getElementById('teacher_lesson_content_header')
            if(teacher_header)
            teacher_header.style.width = 'calc(100% - 144px)'
            let teacher_bread_crumb = document.getElementById('teacher_breadcrumb_id')
            if(teacher_bread_crumb)
            teacher_bread_crumb.style.marginLeft = '86px'
            let logout_id = document.getElementById('logout_id')
            if(logout_id){
                logout_id.style.marginLeft = '0px'
                logout_id.style.transition = ' all 0.4s ease-out'
            }
        } 
    }


    useEffect(()=>{

        if(window.innerWidth > 1240 && window.location.href.indexOf('teacher') > -1){
            document.getElementById('app_main_page_container').style.paddingLeft = '80px'
        }
        // else if (window.innerwidth > 1240 && window.location.href.indexOf('teacher') < 0){
        //     document.getElementById('app_main_page_container').style.paddingLeft = '166px'
        // }

    },[])

    function handleresize(){
        //  if(window.innerwidth <=1240){
        //     document.getElementById('app_main_page_container').style.paddingLeft = '0px'
        // }
        // else if(window.innerwidth > 1240 && window.location.href.indexOf('teacher') > -1){
        //     document.getElementById('app_main_page_container').style.paddingLeft = '80px'
        // }   
        // else{
        //     document.getElementById('app_main_page_container').style.paddingLeft = '166px'
        // }
        if(window.innerWidth > 1240 && window.location.href.indexOf('teacher') > -1){
            document.getElementById('app_main_page_container').style.paddingLeft = '80px'
        }
        else if(window.innerWidth <= 1240 && window.location.href.indexOf('teacher') > -1){
            document.getElementById('app_main_page_container').style.paddingLeft = '0px'
        }   

        if (window.innerWidth <= 1240 && window.location.href.indexOf('teacher') > -1){
            let teacher_header = document.getElementById('teacher_lesson_content_header')
            if(teacher_header)
            teacher_header.style.width = 'calc(100% - 64px)'
            let teacher_bread_crumb = document.getElementById('teacher_breadcrumb_id')
            if(teacher_bread_crumb)
            teacher_bread_crumb.style.marginLeft = '0px'
        }  
        else if(window.innerWidth > 1240 && window.location.href.indexOf('teacher') > -1){
            let teacher_header = document.getElementById('teacher_lesson_content_header')
            if(teacher_header)
            teacher_header.style.width = 'calc(100% - 144px)'
            let teacher_bread_crumb = document.getElementById('teacher_breadcrumb_id')
            if(teacher_bread_crumb)
            teacher_bread_crumb.style.marginLeft = '86px'
        }        
       
    }

    useEffect(()=>{
            window.addEventListener('resize', handleresize);
        return () => {
            window.removeEventListener('resize', handleresize);
        };
        handleresize();
    },[])

    return (
        <div> 
       
        <div className='nav_bar_logo_wrapper'>
            <div className='nav_bar_logo_container' style={window.location.href.indexOf('teacher') > -1 ? {backgroundColor: '#FFF'} : {backgroundColor: ''}}>
                 <div className='logo_container'> </div>
            </div>
        </div>
         <NavHeader/>
        <div className='nav_bar_wrapper'>
            <div className='nav_bar_container'  onMouseEnter={expandpage} onMouseLeave={compresspage} id='nav_bar_container_id'>
                <span className='icon_menu' onClick={() => document.getElementById('the_header').classList.toggle('show_responsive')} />
               
                <ul className='nav_ul'>
                    {
                        menuList.map((nav) => {
                        let nav_class = nav.name.toLowerCase().replace(' ', '_');

                        return (
                            <li className='nav_li' key={`nav_menu_${nav.id}`}>
                            <NavLink
                                reloadDocument
                                to={nav.path}
                                className={({isActive}) => `nav_anchor ${nav_class}` + (isActive ? ' active' : '')}
                                onClick={() => {document.getElementById('the_header').classList.remove('show_responsive')}}
                            >
                                <span className='nav_title'>{nav.name}</span>
                            </NavLink>
                            </li>
                        )
                        })
                    }
                </ul>
                <div className='nav_bar_logout_container'>
                    <div className={`nav_bar_logout_button ${showLogoutPopup ? 'active' : ''}`} ref={logoutRef} onClick={()=>{setShowLogoutPopup(!showLogoutPopup)}}>
                        
                    </div>
                </div>
            </div>
        </div>
            <div  >
               {showLogoutPopup && <div className={`logout_popup`} id='logout_id'>
                    <div className='logout_popup_button border' onClick={()=>{userlogout()}}>
                        <span className='logout_icn logout'></span>
                        Log Out
                    </div>
                    <div className='logout_popup_button'>
                        <span className='logout_icn settings'></span>
                        Settings
                    </div>
                </div>}
            </div>
        </div>

    );
}

export default NavMenu;
