import { useSession } from './SessionProvider';
import { useEffect, useState,lazy, Children} from 'react';
import { Outlet,useNavigate } from 'react-router-dom';
import { saveUserData } from './helpers';
import { getSessionStatus } from './Validation';
import { checksession } from './Events';
import Header from '../Components/Content/Header';
import Footer from '../Components/Content/Footer';
import '../Content.scss'

export default function ContentRoutes() {

    const { isLoggedIn, user_info } = useSession();
    const [pageInfo, setPageInfo] = useState({});
    const [sessStatusFlag, setSessStatusFlag] = useState(false)
    const navigate = useNavigate()

    let current_url = window.location.href.split('?')[0]
    let current_page = current_url?.split('/').filter(Boolean).pop();
    if(window.location.hostname === current_page){
      current_page = 'home'
  }
    useEffect(()=>{
        // checking only sessStatus is not enough because in some case we are not removing session when it is expired 
        if(getSessionStatus()) {
            let payload = {
                Request:{
                 Client:{
                     platform: 'web'
                 }
               }
             }
             console.log('payload',payload)
              checksession(payload).then((res)=>{
                 if (res){
                  
                  if(res.data?.session.status){
                    const data = res.data.session
                    const user_info = data.user_data
                    const userData = {
                      isLoggedIn: data.status,
                      user_info
                    };
                     saveUserData(userData);
                     setSessStatusFlag(false)
                     navigate('/home')
                  }
                  else{
                   setSessStatusFlag(true)
                  }
                }
              })
        }
        else {
          setSessStatusFlag(true)
        }
    },[])

  return (
    <div className='content_page_wrapper'>
      <div className={`content_page_container ${current_page === 'login' || current_page === 'sign-up' ? 'content_login' : 'content'}`}>
        <Header/>
      {
        sessStatusFlag && 
        <div className='content_main_wrapper'>
          <div className='content_main_container'>
            <Outlet/>
          </div>
        </div>
      }
      {
        current_page === 'login' || current_page === 'sign-up' ?
        <></>
        :
        <Footer/>
      }
    </div>
    </div>
    
  )
}

