import { updateAssignmentStatus } from "./Events";

export const ASSIGNMENT_PUBLISH_STATE = "publish"

export const  extractTitleAndURL = (html) =>{
    const regex = /<a href="(.*?)"[^>]*>(.*?)<\/a>/;
    const match = html.match(regex);
    
    if (match) {
        const url = match[1];
        const title = match[2];
        return { title, url };
    }

    return { title: null, url: null };
}

export function extractTextFromHTML(htmlString) {
    var parser = new DOMParser();
    
    var doc = parser.parseFromString(htmlString, 'text/html');
    
    return doc.body.textContent.trim();
}

const appendParamsToUrl = (attachment, params) =>{
   
    const {name, url } = attachment;
    try{
        const urlObj = new URL(url);
  
        for (const [paramName, paramValue] of Object.entries(params)) {
            if (urlObj.searchParams.has(paramName)) {
              urlObj.searchParams.set(paramName, paramValue);
            } else {
              urlObj.searchParams.append(paramName, paramValue);
            }
        }
        
        return {
            name: name,
            url: urlObj.toString()
        };
    }
    catch(err){
        console.error("Invalid url",url, err)
        return attachment
    }
   
}

export const getUrlsfromExtensions = (externalInfo) => {
    const attachments = externalInfo?.lesson_attachments?.attachments || [];
    const links = externalInfo?.lesson_links?.links || [];
    const mindfulness = externalInfo?.lesson_sessions?.mindfulness || [];

    let attachment_urls = attachments?.map(a => 
        a?.sub_section?.includes("extension") && {name: a?.name, url:`https://${window.location.hostname}/view?key_path=${a?.key_path}`}
    ).filter(Boolean); // Filters out falsy values

    let link_urls = links?.map(l => 
        l?.sub_section?.includes("extension") && {name: l?.title, url: l?.url}
    ).filter(Boolean); // Filters out falsy values

    let mindfulness_urls = mindfulness.map(m => 
        m?.sub_section?.includes("extension") && {name: m?.title, url: m?.url}
    ).filter(Boolean); // Filters out falsy values

    attachment_urls = attachment_urls.map(x => appendParamsToUrl(x, {alaia_assignment_uuid: "alaia_assignment_uuid", type: "file"}))
    link_urls = link_urls.map(x => appendParamsToUrl(x, {alaia_assignment_uuid: "alaia_assignment_uuid", type: "others"}))
    mindfulness_urls = mindfulness_urls.map(x => appendParamsToUrl(x, {alaia_assignment_uuid: "alaia_assignment_uuid", type: "mindfulness"}))
    return [...attachment_urls, ...link_urls, ...mindfulness_urls];
}


export async function update_assignemnts(payload){
  
    try{
      const response = await updateAssignmentStatus(payload)
      if (response.data.mwsResponse.Status.code === '000'){
        console.log(response)
      }
      else{
        let error = response.data.mwsResponse.Status.message
        console.log(error)
      }
    }catch(err){
      console.error("Failed to update assignments ",err)
    }
  }