// import { useNavigate } from 'react-router-dom'
// import '../../assets/stylesheets/Content/header.scss'
// import { getImageInfo, getTextValue } from '../Admin/CMS/cms_helper'
// import DOMPurify from 'dompurify'

// export default function Header({pageInfo}){

//     const navigate = useNavigate()
//     const template_name = 'header'

//     return <>
//         <div className="content_header_wrapper">
//             <div className="content_header_container">
//                 <div className="alaia_logo" onClick={()=>{navigate('/')}} id={`${template_name}_block_1`} style={ getImageInfo(template_name,'block_1',pageInfo) ? {content: `url(${getImageInfo(template_name,'block_1',pageInfo)})`} : {}}></div>
//                 <div className="header_navigation_container">
                   
//                     <div className="header_nav_menu" onClick={()=>{navigate('/about')}} id={`${template_name}_block_2`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTextValue(template_name,'block_2',pageInfo),{ADD_ATTR: ['target'] })}}></div>
//                     <div className="header_nav_menu" onClick={()=>{navigate('/login')}} id={`${template_name}_block_3`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getTextValue(template_name,'block_3',pageInfo),{ADD_ATTR: ['target'] })}}></div>
//                 </div>
//             </div>
//         </div>
//     </>
// }

import React,{useState, useRef, useEffect} from "react";
import '../../assets/stylesheets/Content/header.scss'
import AlaiaLogo from '../../assets/images/Alaia.svg'
import AlaiaLogoResponsive from '../../assets/images/banner_alaia_logo.svg'
import { Link, useNavigate } from "react-router-dom";

export default function Header(){

    const navigate = useNavigate();
    const navRef = useRef();
    const [width, setWidth] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    let current_url = window.location.href.split('?')[0]
    let current_page = current_url?.split('/').filter(Boolean).pop();
   
    if(window.location.hostname === current_page){
        current_page = 'home'
    }

    if(current_url.indexOf('article') > -1 || current_url.indexOf('blog') > -1){
        current_page = 'impact'
    }

    const toggleMenu = (e) => {
        e.preventDefault();
        const toggle = !isOpen
        setIsOpen(toggle);
    };

    const outsideClick = (event) => {
        if (navRef && navRef.current) {
            if (navRef.current.contains(event.target)) {
                return;
            }
        // outside click 
         setIsOpen(false)
        }
    };

    useEffect(() => {
      document.addEventListener('click', outsideClick);
      
      return () => {
        document.removeEventListener('click', outsideClick);
      };
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
    
        handleResize();
    
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); 

    return <>
        <header className={`content_header ${current_page}`} ref={navRef}>
            <div className={`content_header_container ${current_page} ${isOpen ? 'show' : 'hide'}`}>
               
                <div className={`content_header_logo ${current_page}`} onClick={()=>{navigate('/')}}>
                    <img src={ width > 1240 ? AlaiaLogo : current_page === 'login' ?  isOpen ? AlaiaLogo : AlaiaLogoResponsive : AlaiaLogo} alt="Logo" loading="lazy"/>
                </div>
                <nav className="content_header_nav">
                    <ul>
                    <li className={`content_header_list ${current_page} ${current_page==='home' ? 'selected' : ''}`}> <Link className={`content_header_nav_menu ${current_page}  ${current_page==='home' ? 'selected' : ''}`} to="/">Home</Link></li>
                    <li className={`content_header_list ${current_page} ${current_page==='about-us' ? 'selected' : ''}`}><Link className={`content_header_nav_menu ${current_page}  ${current_page==='about-us' ? 'selected' : ''}`} to="/about-us">About Us</Link></li>
                    <li className={`content_header_list ${current_page} ${current_page==='wellness' ? 'selected' : ''}`}><Link className={`content_header_nav_menu ${current_page}  ${current_page==='wellness' ? 'selected' : ''}`} to="/wellness">Wellness</Link></li>
                    <li className={`content_header_list ${current_page} ${current_page==='learn' ? 'selected' : ''}`}><Link className={`content_header_nav_menu ${current_page}  ${current_page==='learn' ? 'selected' : ''}`} to="/learn">Learn</Link></li>
                    <li className={`content_header_list ${current_page} ${current_page==='impact' ? 'selected' : ''}`}><Link className={`content_header_nav_menu ${current_page}  ${current_page==='impact' ? 'selected' : ''}`} to="/impact">Impact</Link></li>
                    <li className="content_header_list_li"><button className="try_alaia" onClick={()=>{navigate("/sign-up")}}>Try Alaia</button></li>
                    <li className={`content_header_list ${current_page} ${current_page==='login' ? 'selected' : ''}`}><Link className={`content_header_nav_menu ${current_page}  ${current_page==='login' ? 'selected' : ''}`} to="/login">Sign In</Link></li>
                    </ul>
                </nav>
                {isOpen && <nav className={`responsive_content_header_nav ${current_page} ${isOpen ? 'show' : 'hide'}`}>
                    <ul className="responsive_content_header_nav_container">
                        <li className={`content_header_list_responsive ${current_page} ${current_page==='home' ? '' : ''}`}> <Link className={`content_header_nav_menu ${current_page}  ${current_page==='home' ? '' : ''}`} to="/" onClick={()=>{setIsOpen(false)}}>Home</Link></li>
                        <li className={`content_header_list_responsive ${current_page} ${current_page==='about-us' ? '' : ''}`}><Link className={`content_header_nav_menu ${current_page}  ${current_page==='about-us' ? '' : ''}`} to="/about-us" onClick={()=>{setIsOpen(false)}}>About Us</Link></li>
                        <li className={`content_header_list_responsive ${current_page} ${current_page==='wellness' ? '' : ''}`}><Link className={`content_header_nav_menu ${current_page} ${current_page==='wellness' ? '' : ''}`} to="/wellness" onClick={()=>{setIsOpen(false)}}>Wellness</Link></li>
                        <li className={`content_header_list_responsive ${current_page} ${current_page==='learn' ? '' : ''}`}><Link className={`content_header_nav_menu ${current_page}  ${current_page==='learn' ? '' : ''}`} to="/learn" onClick={()=>{setIsOpen(false)}}>Learn</Link></li>
                        <li className={`content_header_list_responsive ${current_page} ${current_page==='impact' ? '' : ''}`}><Link className={`content_header_nav_menu ${current_page}  ${current_page==='impact' ? '' : ''}`} to="/impact" onClick={()=>{setIsOpen(false)}}>Impact</Link></li>
                        <li className={`content_header_list_responsive ${current_page} last ${current_page==='login' ? '' : ''}`}><Link className={`content_header_nav_menu ${current_page}  ${current_page==='login' ? '' : ''}`} to="/login" onClick={()=>{setIsOpen(false)}}>Sign In</Link></li>
                    </ul>
                    <div className="bottom_container">
                        <div className="try_alaia_container">
                            <div className="try_alaia_container_button"><button className="try_alaia" onClick={()=>{navigate("/sign-up"); setIsOpen(false)}}>Try Alaia</button></div>
                            <div className="try_alaia_container_text">Alaia LLC ©2024 An Academica Company</div>
                            <div className="content_header_responsive_slider_container">
                                <span className="content_header_responsive_slider">
                                </span>
                            </div>
                        </div>
                     
                    </div>
                </nav>}
                <div>
                    {!isOpen && <button className="try_alaia tab_view" onClick={()=>{navigate("/sign-up");}}>Try Alaia</button>}
                    <div className={`content_header_resposive_option ${isOpen ? 'show' : 'hide'} ${current_page}`} onClick={toggleMenu}></div>
                </div>
            </div>
        </header>
    </>
}